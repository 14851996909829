import './vendor/cookieconsent.umd.js';

CookieConsent.run({
  // page_scripts: true,
  disablePageInteraction: true,
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom center",
      equalWeightButtons: true,
      flipButtons: false
    },
    preferencesModal: {
      layout: "box",
      position: "right",
      equalWeightButtons: true,
      flipButtons: false
    }
  },
  categories: {
    necessary: {
      readOnly: true
    },
    analytics: {},
  },
  language: {
    default: "de",
    autoDetect: "document",
    translations: {
      en: {
        consentModal: {
          title: "Cookies",
          description: "A short explanation of what information we are storing.",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          showPreferencesBtn: "Manage preferences",
          closeIconLabel: "Reject all and close",
          footer: "<a href=\"/policy\">Privacy Policy</a>\n<a href=\"/gtc\">Terms and conditions</a>"
        },
        preferencesModal: {
          title: "Consent Preferences Center",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          savePreferencesBtn: "Save preferences",
          closeIconLabel: "Close modal",
          serviceCounterLabel: "Service|Services",
          sections: [
            {
              title: "Your privacy",
              description: "Our site uses cookies and other similar technologies like pixel tags and web beacons. A cookie is a small piece of data that a website asks your browser to store on your computer or mobile device. Cookies allow a site to 'remember' your actions or preferences over time. So when you visit our site, the cookies may store or retrieve information on your browser, which may be about you, your preferences and/or your device. We use cookies, as well as third party cookies, to operate and improve the site, measure our audience, enhance your experience and provide you with content and advertising tailored to your interests based on your browsing activities and actions on this and other sites. For instance, cookies enable us to assess how many users access or use our service, which content, products, and features of our service most interest our users, what types of content our users like to see, and how our service performs from a technical point of view. Click on the different category headings to manage your cookie preferences and find out more about the cookies we use and why. Click on Allow All if you want all cookies enabled (although essential cookies are always enabled). Please keep in mind that if you do not accept certain cookies, it may impact your experience of the site and the services we are able to offer."
            },
            {
              title: "Strictly necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
              description: "These cookies are strictly necessary for the site to work properly and cannot be disabled in our systems. These cookies enable core functionality such as security, network management, and accessibility. We do not always process personal data when we place essential cookies. In the instances we do, we rely on the following legal basis for processing: they are necessary to make the site function for you to view. Please note that you can set your browser to block these cookies, but this may affect how the site functions.",
              linkedCategory: "necessary"
            },
            // {
            //   title: "Performance Cookies",
            //   description: "These cookies help improve our site by collecting information on how you use it. This information enables us to improve site performance, correct any errors and provide you a personalized experience. For instance, it helps us understand how long you spend on different sections of the site and from where you are accessing it; it also helps us see if you have visited our sites before so that we can not only identify the number of unique visitors but possibly deliver you a personalized experience.",
            //   linkedCategory: "performance"
            // },
            {
              title: "Functionality cookies",
              description: "These cookies enable our site to remember choices you make, for example, the language you select, or to enable services, such as viewing videos. These cookies allow us to tailor the site to you and provide enhanced functionality. Without these cookies, certain functionalities may not be available.",
              linkedCategory: "functions"
            },
            {
              title: "More information",
              description: "For any query in relation to out policy on cookies and your choices, please <a class=\"cc__link\" href=\"/imprint\">contact us</a>."
            }
          ]
        }
      },
      de: {
        consentModal: {
          title: "Cookies",
          description: "Eine kurze Erklärung, welche Daten wir speichern.",
          acceptAllBtn: "Alle akzeptieren",
          acceptNecessaryBtn: "Alle ablehnen",
          showPreferencesBtn: "Einstellungen verwalten",
          closeIconLabel: "Alle ablehnen und schließen",
          footer: "<a href=\"/policy\">Datenschutz</a>\n<a href=\"/gtc\">Bedingungen und Konditionen</a>"
        },
        preferencesModal: {
          title: "Präferenzen für die Zustimmung",
          acceptAllBtn: "Alle akzeptieren",
          acceptNecessaryBtn: "Alle ablehnen",
          savePreferencesBtn: "Einstellungen speichern",
          closeIconLabel: "Modal schließen",
          serviceCounterLabel: "Dienstleistungen",
          sections: [
            {
              title: "Ihre Privatsphäre",
              description: "Auf unserer Seite verwenden wir Cookies und ähnliche Technologien wie Pixel Tags und Web Beacons. Cookies sind kleine Datendateien, die von Webseiten über den Browser auf Ihrem Computer oder Mobilgerät gespeichert werden. Mithilfe von Cookies werden Ihre Aktivitäten und Einstellungen auf einer Seite hinterlegt. Bei Ihrem Besuch auf unserer Seite speichern Cookies Informationen im Browser. Dies können Informationen über Sie, Ihre Einstellungen und/oder Ihr Endgerät sein. Wir setzen Cookies und Cookies von Drittanbietern zu folgenden Zwecken ein: Betrieb und optimierte Nutzung unserer Seite, Messung unserer Seitenbesuche, Verbesserung Ihrer Nutzererfahrung und Anzeige personalisierter Werbung und Inhalte (basierend auf Seitenaufrufen und Interessen) auf dieser und anderen Seiten. Mithilfe von Cookies können wir beispielsweise auswerten, wie viele Nutzer auf unser Angebot zugreifen und dieses nutzen, welche Inhalte, Produkte und Besonderheiten unseres Angebots für unsere Nutzer von Relevanz sind, welche Art von Inhalten sich unsere Nutzer wünschen und wie leistungsfähig unser Serviceangebot in technischer Hinsicht ist. Zur Verwaltung Ihrer Cookie-Einstellungen klicken Sie einfach auf die unterschiedlichen Kategorien. So können Sie mehr darüber erfahren, welche Cookies wir einsetzen und welchem Zweck sie dienen. Klicken Sie auf 'Alle akzeptieren', wenn Sie alle Cookies aktivieren möchten (Hinweis: Essenzielle Cookies sind standardmäßig immer aktiviert). Das Deaktivieren bestimmter Cookies kann Ihr Nutzererlebnis auf unserer Seite sowie die angebotenen Services einschränken."
            },
            {
              title: "Unbedingt notwendige Cookies <span class=\"pm__badge\">Immer Aktiviert</span>",
              description: "Diese Cookies sind für die einwandfreie Funktionstüchtigkeit unserer Seite essenziell und können nicht von Ihnen deaktiviert werden. Sie ermöglichen wesentliche Funktionen wie Sicherheit, Netzwerkmanagement und Zugriff. Die Verwendung essenzieller Cookies ist nicht automatisch mit der Verarbeitung personenbezogener Daten verbunden. Werden Cookies mit Personenbezug eingesetzt, erfolgt dies in Einklang mit den geltenden Rechtsvorschriften und nur in Fällen, in denen dies für eine reibungslose Nutzung unserer Seite unbedingt erforderlich ist. In Ihren Browser-Einstellungen können Sie diese Cookies möglicherweise blockieren. Dies kann jedoch zur Beeinträchtigung einiger Funktionen der Seite führen.",
              linkedCategory: "necessary"
            },
            // {
            //   title: "Leistungs-Cookies",
            //   description: "Diese Cookies sammeln Informationen über Ihr Nutzerverhalten. Mithilfe dieser Informationen können wir die Leistungsfähigkeit unserer Seite verbessern, Fehler beheben und für ein zufriedenstellendes Nutzererlebnis sorgen. Beispielsweise können wir so einsehen, wieviel Zeit Sie auf unserer Seite verbringen, von wo aus Sie auf unsere Seite zugreifen und ob Sie unsere Seite zum ersten oder wiederholten Mal aufrufen. Auf diese Weise können wir die Gesamtbesucherzahl unserer Seite erfassen.",
            //   linkedCategory: "performance"
            // },
            {
              title: "Funtionscookies",
              description: "Mithilfe dieser Cookies können wir Ihre Einstellungen auf unserer Seite speichern, z.B. die Einstellungen zur Sprache oder zur Freigabe von Services wie Videofunktion. So können wir die Nutzung unserer Seite auf Sie zuschneiden und Ihnen erweiterte Funktionen anbieten. Ohne diese Cookies können Sie bestimmte Funktionen möglicherweise nicht nutzen.",
              linkedCategory: "functions"
            },
            {
              title: "Weitere Information",
              description: "Bei Fragen zu unseren Cookie-Richtlinien und Ihren Auswahlmöglichkeiten, bitte <a class=\"cc__link\" href=\"/imprint\">kontaktieren Sie uns</a>."
            }
          ]
        }
      }
    }
  },

  // onConsent: function () {
  //     if (CookieConsent.acceptedCategory('analytics')) {

  //         // recommender 'collect'
  //         const coll = document.querySelectorAll('.recc');

  //         for (let i = 0; i < coll.length; i++) {
  //             coll[i].addEventListener('click', function () {
  //                 console.table([this.dataset.reccPart, this.dataset.reccLoad]);
  //                 collector.collect(this.dataset.reccPart, this.dataset.reccLoad);
  //             });
  //         }

  //         // recommender 'collectSubmit'
  //         const collSub = document.querySelectorAll('.recc__submit');
  //         for (let i = 0; i < collSub.length; i++) {
  //             collSub[i].addEventListener('submit', function (event) {
  //                 event.preventDefault();
  //                 console.table([this.dataset.reccFunction, this.dataset.reccPart, this.dataset.reccLoad]);
  //                 collector.collectSubmit(this.dataset.reccFunction, this.dataset.reccPart, this.dataset.reccLoad);
  //             });
  //         }
  //     }
  // }
});
